import React from 'react'
import Alert from '../../components/Alert/Alert'

const ProfileDenied = props => {
    return (
        <div className="Page">
            <h1 className="Title Page_title">Sorry</h1>
            <Alert>
                <p className="Text Text__md">Your profile is not valid to use. Please contact our support.</p>
            </Alert>
        </div>
    )
}

export default ProfileDenied
