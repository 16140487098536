import React from 'react'
import './Tabs.scss'

export default function ({ className = '', children = [], vertical = false }) {
    const classList = `${className} Tabs Tabs__${vertical ? 'vertical' : 'horizontal'}`
    return <div className={classList}>
        <ul className="Tabs_items">
            {children.map((child, idx) => (
                <li className="Tabs_item" key={`tabs_${idx}`}>
                    {React.cloneElement(child, { width: vertical ? 'available' : null })}
                </li>
            ))}
        </ul>
    </div>
}