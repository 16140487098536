import React from 'react'
import Button from '../Button'
import './TabItem.scss'

export default function ({ className = '', active = false, children = null, ...other }) {


    const classList = `${className} TabItem ${active ? 'Button__active' : 'Button__transparent'}`

    return <Button className={classList} {...other}>
        {children}
    </Button>
}