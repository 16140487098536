import { AUTH_TOKEN_KEY } from "../utils/const"

export default {
    setToken (token) {
        localStorage.setItem(AUTH_TOKEN_KEY, token)
    },

    getToken () {
        return localStorage.getItem(AUTH_TOKEN_KEY)
    },

    removeToken () {
        localStorage.removeItem(AUTH_TOKEN_KEY)
    },

    logout () {
        console.log(this)
        this.removeToken()
        document.location.href = '/'
    }
}