import authService from '../../services/auth'

export const initialState = {
  token: authService.getToken(),
  loading: true,
  profile: null,
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case 'AUTH/SET_TOKEN':
      authService.setToken(action.token)
      return {...state, token: action.token}
    case 'AUTH/LOADING':
      return {...state, loading: true}
    case 'AUTH/ERROR':
    case 'AUTH/REMOVE_TOKEN':
      return {...state, token: null, loading: false, profile: null}
    case 'AUTH/LOADED':
      return {...state, profile: action.profile, loading: false}
    default:
      return state
  }
}
