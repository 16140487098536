import React, { useState } from 'react'
import Input from '../Input'
import Button from '../Button/index'
import FormField from '../FormField/index'
import PhoneInput from '../InputPhone/index'
import Form from '../Form/index'
import Link from '../Link/index'
import Spinner from '../Spinner/Spinner'
import { requestAuthCode, requestAuthToken } from '../../store/actions/index'
import { useDispatch, useSelector } from 'react-redux'
import './SigninForm.scss'

export const LOGIN_STEP_PHONE = 1
export const LOGIN_STEP_PIN = 2
export const ERR_INCORRECT_PIN = 'Неправильный код'

export default function ({ className = '', submit }) {
    const initialStep = LOGIN_STEP_PHONE
    const [phone, setPhone] = useState()
    const [pin, setPin] = useState()
    const [step, setStep] = useState(initialStep)
    const [isPinIncorrect, setPinIncorrect] = useState(false)
    const loading = useSelector(state => state.auth.loading)
    const dispatch = useDispatch()
    const ButtonLabel = step === LOGIN_STEP_PHONE ? 'Получить код' : 'Продолжить'

    async function nextStep (e) {
        if (step === LOGIN_STEP_PHONE) {
            await dispatch(requestAuthCode(phone))
            setStep(LOGIN_STEP_PIN)
        }
        if (step === LOGIN_STEP_PIN) {
            try {
                await dispatch(requestAuthToken(phone, pin))
                submit()
            } catch (err) {
                setPin('')
                setPinIncorrect(true)
            }
        }
    }

    return <div className={`Signin ${className}`}>
        <h1 className="Title Signin_title">
            Вход в кабинет
        </h1>
        <Form onSubmit={nextStep}>

            {step === LOGIN_STEP_PHONE && <FormField>
                <PhoneInput name="phone" value={phone} onChange={setPhone} placeholder="Номер телефона" width="available" />
            </FormField>}

            {step === LOGIN_STEP_PIN && <FormField>
                <Input value={pin}
                       onChange={setPin}
                       width="available"
                       error={isPinIncorrect ? ERR_INCORRECT_PIN : ''}
                       placeholder="Код из смс" />
            </FormField>}

            <div className="Signin_footer">
                <Button type="submit" view="extra">
                    {ButtonLabel}
                </Button>
            </div>
        </Form>
    </div>
}
