import React from 'react'
import Documents from '../../components/Document/Documents'
import './PageDocuments.scss'

export default function () {
    return <div className="PageDocuments">
        <h1 className="Title PageDocuments_title">
            Документы
        </h1>
        <Documents />
    </div>
}