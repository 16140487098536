import React from 'react'
import { useParams } from 'react-router-dom'
import TheFrame from '../../components/Frame'
import Link from '../../components/Link'
import Document from '../../components/Document/Document'
import './PageDetails.scss'
import {formatCertName} from "../../utils/format"
import { TYPE_SIGNED, TYPE_UNSIGNED } from '../../components/Document/sign.types'

export default function ({ document }) {
    const { id } = useParams()
    const { signed } = document
    const signType = signed ? TYPE_SIGNED : TYPE_UNSIGNED
    const signLabel = signed ? 'Подписаны' : 'На подписании'

    return <div className="Page PageDetails">
        <div className="PageDetails_nav">
            <Link
                to={`/profile?show=${signType}`}>
                {signLabel}
            </Link>
        </div>

        <h1 className="Title Page_title">
            Сертификат онлайн подписи
            <br /><br />
            {formatCertName(document.filename)}
        </h1>

        <TheFrame>
            <Document document={document}/>
        </TheFrame>
    </div>
}