import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import './Dropzone.scss'

export default function ({ onChange, className = '', disabled = false, multiple = false, ...rest }) {
    const [files, setFiles] = useState([])

    function change (fileList) {
        setFiles(fileList)
        onChange && onChange(fileList)
    }

    function deleteFile (e, file) {
        e.stopPropagation()
        const idx = files.findIndex(f => f.name === file.name)
        if (idx === false) return
        const newFiles = files.slice()
        newFiles.splice(idx, 1)
        change(newFiles)
    }


    return <Dropzone onDrop={change} multiple={multiple} disabled={disabled} {...rest}>
        {({getRootProps, getInputProps}) => {
            return <section className={`${className} Dropzone`} >
                <div {...getRootProps()} tabIndex={''} className="Dropzone_inner">
                    <input {...getInputProps()} />
                    <button type="button" className="Dropzone_button" disabled={disabled}>Прикрепить файл</button>
                    {files.length
                        ? <ul className="Dropzone_files">
                            {files.map(f => (
                                <li key={f.name} className="Dropzone_file">
                                    <button type="button" className="Dropzone_fileDelete" title="Удалить файл" onClick={(e) => deleteFile(e, f)}>&times;</button>
                                    <div className="Dropzone_fileName">{f.name}</div>
                                </li>
                            ))}
                        </ul>
                        : null
                    }
                </div>
            </section>

        }}
    </Dropzone>
}
