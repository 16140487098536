import React, { useState } from 'react'
import './Filepicker.scss'

export default function ({
    multiple = false,
    accept = '',
    name = '',
    onChange,
    className = '',
    disabled = false,
    children
}) {
    const change = (e) => {
        const files = Array.from(e.target.files || e.dataTransfer.files)
        onChange(multiple ? files : files[0])
    }
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = accept
    input.name = name
    input.multiple = multiple
    input.onchange = change
    input.disabled = disabled

    return <button className={`${className} Filepicker`}
                   type='button'
                   onClick={() => input.click()}
                   disabled={disabled}>
            {children}
        </button>
}