import React from 'react'

export default function () {
    return <div className="ComponentPlaceholder">
        <div className="Text Text__md" style={{ marginBottom: 20 }}>Загрузка...</div>
        <svg width="680" height="265" viewBox="0 0 680 265" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="150" height="40" rx="4" fill="#00103D" fillOpacity="0.06"/>
            <rect x="167" width="150" height="40" rx="4" fill="#00103D" fillOpacity="0.06"/>
            <rect x="495" width="185" height="40" rx="4" fill="#00103D" fillOpacity="0.06"/>
            <rect y="64" width="39" height="51" rx="4" fill="#00103D" fillOpacity="0.06"/>
            <rect x="50" y="64" width="630" height="20" fill="#00103D" fillOpacity="0.06"/>
            <rect x="50" y="95" width="279" height="20" fill="#00103D" fillOpacity="0.06"/>
            <rect y="139" width="39" height="51" rx="4" fill="#00103D" fillOpacity="0.06"/>
            <rect x="50" y="139" width="537" height="20" fill="#00103D" fillOpacity="0.06"/>
            <rect x="50" y="170" width="344" height="20" fill="#00103D" fillOpacity="0.06"/>
            <rect y="214" width="39" height="51" rx="4" fill="#00103D" fillOpacity="0.06"/>
            <rect x="50" y="214" width="605" height="20" fill="#00103D" fillOpacity="0.06"/>
            <rect x="50" y="245" width="229" height="20" fill="#00103D" fillOpacity="0.06"/>
        </svg>
    </div>
}