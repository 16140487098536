import React from 'react'
import Input from 'arui-feather/input'
import './input.scss'

export default function (props) {
    const {
    className = '',
    ...otherProps
} = props
    return <Input className={`${className} Input`} size="s" view="filled" {...otherProps} />
}
