import React from 'react'
import Alert from '../../components/Alert/Alert'
import './PageError.scss'

export default function ({ className = '', title = null, text, children }) {
    return <div className={`${className} Page PageError`}>
        <h1 className="Title Page_title PageError_title">
            {title}
        </h1>

        <Alert>
            <div className="PageError_text">
                {children ? children : text}
            </div>
        </Alert>
    </div>
}