export const formatPartner = partner => {
    if (partner.legal_form === 'organization') {
        return partner.organization_name
    } else {
        let fio = `${partner.last_name} ${partner.first_name}`
        if (partner.patronymic_name) {
            fio = `${fio} ${partner.patronymic_name}`
        }

        if (partner.legal_form === 'entrepreneur') {
            fio = `ИП ${fio}`
        }
        return `${fio}`
    }
}