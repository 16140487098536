import {callAPI} from '../../utils/api'

export const loadingUserDocuments = () => {
  return {type: 'USER_DOCUMENTS/LOADING'}
}

export const setUserDocuments = data => {
  return {type: 'USER_DOCUMENTS/SET', data}
}

export const loadUserDocuments = id => {
  return async (dispatch, getState) => {
    dispatch(loadingUserDocuments())
    try {
      const response = await callAPI('profile/cases/')
      dispatch(setUserDocuments(response.data))
    } catch (error) {
      dispatch(setUserDocuments([]))
    }
  }
}
