import React from 'react'
import Btn from 'arui-feather/button'
import './Button.scss'

export default function (props) {
    const {
        className = '',
        noSizeChange = false,
        ...otherProps
    } = props
    return <Btn className={`${className} Button ${noSizeChange ? 'Button__noSizeChange' : ''}`} {...otherProps}></Btn>
}