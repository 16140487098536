import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {loadDocument} from '../../store/actions/documents'
import './Init.scss'

const DocumentInit = ({id, dispatch, onRefresh}) => {
  useEffect(() => {
    const refreshInterval = setInterval(() => {
      onRefresh(id, dispatch)
    }, 2000)

    return () => {
      clearInterval(refreshInterval)
    }
  }, [])

  return (
    <div className="DocumentInit">
      Загрузка...
    </div>
  )
}

DocumentInit.defaultProps = {
  onRefresh: (id, dispatch) => dispatch(loadDocument(id)),
}

export default connect(
  null,
  null,
)(DocumentInit)
