import React, {useState, useEffect} from 'react'
import Input from '../Input'
import Button from '../Button'
import PhoneInput from '../InputPhone/index'
import {useDispatch,useSelector} from 'react-redux'
import './DocumentSignForm.scss'
import {commitLoginSignature} from "../../store/actions/signature"
import {requestAuthCode} from "../../store/actions/auth"

export default function ({ id, demo = false, onSigned }) {
    const [code, setCode] = useState()
    const currentPhone = useSelector((state) => state.auth.profile && state.auth.profile.username)
    const [phone, setPhone] = useState(currentPhone)
    const dispatch = useDispatch()
    const [error, setError] = useState(false)
    const [initialized, setInitialized] = useState(!!phone)

    useEffect(() => {
        if (phone) requestCode()
    }, [])

    async function sign (e) {
        e && e.preventDefault()
        try {
            await dispatch(commitLoginSignature(
                id,
                phone,
                code,
                demo
            ))
            onSigned()
        } catch(err) {
            setError('Упс! Код не подходит, попробуйте еше раз')
            // onSigned()
        }
    }

    function requestCode (e) {
        if (e) e.preventDefault()
        try {
            dispatch(requestAuthCode(phone))
            setInitialized(true)
        } catch (err) {
            setError('Ошибка')
        }
    }

    function onKeyUpInput () {
        if (code.length < 6) setError(false);
        else if (code.length === 6) return sign();
    }

    function onFocusInput () {
        setError(false);
    }

    return <div className="DocumentSignForm">
        {!initialized
            ? <form className="DocumentSignForm_inner" onSubmit={requestCode}>
                <PhoneInput label={'  Номер телефона'} width="available" value={phone} onChange={setPhone}/>
                <Button type="submit" view="extra">Получить код</Button>
            </form>

            : <form className="DocumentSignForm_inner" onSubmit={sign}>
                <Input
                    clear={true}
                    maxLength = {6}
                    className={`input_pin ${error ? 'input_er' : ''}`}
                    // rightAddons={''}
                    mask='111111'
                    label={'  Введите код из SMS'}
                    width="available"
                    type="text"
                    onKeyUp={onKeyUpInput}
                    onFocus={onFocusInput}
                    value={code} onChange={setCode}/>
                {/*<Button type="submit" view="extra">Подтвердить</Button>*/}
            </form>
        }

        {error
            ? <div className="Text Text__sm Text__error" style={{ marginTop: 4}}>{error}</div>
            : null
        }
    </div>
}