import React, { useState } from 'react'
import Input from '../Input'
import Form from '../Form'
import FormField from '../FormField'
import Button from '../Button'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {createCase} from '../../store/actions/documents'
import './NewDocumentForm.scss'
import Filepicker from '../Filepicker/Filepicker'
import upload from '../../services/upload'

const ERR_UPLOAD_FAIL = 'Не удалось загрзуить документ.'

const NewDocumentForm = ({partner, history, dispatch}) => {
    const accept = 'application/pdf'
    const [url, setURL] = useState('')
    const [error, setError] = useState(null)
    const [uploading, setUploading] = useState(false)

    const onFileChange = async (e) => {
        setUploading(true)
        setError('')
        try {
            const { local_url } = await upload.uploadDocument(e)
            setURL(local_url)
        } catch (err) {
            console.error(err)
            setError('Не удалось загрузить документ')
        }
        setUploading(false)
    }

    async function submit (e) {
        try {
            const response = await dispatch(createCase(url))
            history.push(`/case/${response.data.id}`)
        } catch (err) {
            const { response } = err
            if (!response) return ERR_UPLOAD_FAIL
            const msg = response.data ? (response.data.error || ERR_UPLOAD_FAIL) : ERR_UPLOAD_FAIL
            setError(msg)
        }
    }

    return <Form onSubmit={submit} className="NewDocumentForm">
        <div style={{ marginBottom: 4  }} className="Text Text__md">Ссылка на PDF документ</div>
        <FormField>
            <Input value={url} onChange={setURL} width="available" error={error}/>
            {/*comment / uncomment mark*/}
            <div style={{ marginTop: 16 }}>
                <Filepicker disabled={uploading} accept={accept} onChange={onFileChange}>{uploading ? 'Загрузка...' : 'Выбрать файл'}</Filepicker>
            </div>
        </FormField>
        <Button type="submit" disabled={uploading} view="extra">Отправить</Button>
    </Form>
}


export default connect(
    null,
    null,
)(withRouter(NewDocumentForm))
