import React, { Fragment } from 'react'
import {connect} from 'react-redux'
import ProfileDenied from './Denied'
import ProfilePending from './Pending'
import Documents from '../Document/Documents'
import Registration from '../Registration/Registration'
import * as STATUSES from '../../store/profile.statuses'

const statusToComponent = {
  [STATUSES.APPROVED]: Documents, //ProfileDetail,
  [STATUSES.DENIED]: ProfileDenied,
  [STATUSES.EMPTY]: Registration, //ProfileInit,
  [STATUSES.PENDING]: ProfilePending,
}

const Profile = ({profile, dispatch}) => {
  let status = null
  if (profile) {
    status = profile.status
  }

  const StatusComponent = statusToComponent[status]

  return <Fragment>{StatusComponent && <StatusComponent dispatch={dispatch} />}</Fragment>
}

export default connect(
  state => ({profile: state.auth.profile}),
  null,
)(Profile)
