import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Alert from '../../components/Alert/Alert'
import Tabs from '../../components/Tabs/Tabs'
import TabItem from '../../components/Tabs/TabItem'
import TheFrame from '../../components/Frame'
import RegistrationForm from '../../components/Forms/RegistrationForm'
import TYPES from '../../store/customer.types'
import './PageRegistration.scss'
const LEGAL_FORM_KEY = 'legal_form'

const typesInfo = {
    [TYPES.TYPE_PERSON]: 'Физ. лицо',
    [TYPES.TYPE_ENTERPRENEUR]: 'ИП',
    [TYPES.TYPE_ORGANIZATION]: 'Юр. лицо'
}


export default function () {
    const location = useLocation()
    const legalFormFromUrl = (new URLSearchParams(location.search)).get(LEGAL_FORM_KEY)
    const [legalForm, setLegalForm] = useState(legalFormFromUrl)

    if (!legalForm) setLegalForm(TYPES.TYPE_PERSON)

    return <div className="PageRegistration Page">
        <h1 className="Page Page_title Title PageRegistration_title">
            Регистрация
        </h1>

        <Alert className="PageRegistration_alert">
            С&nbsp;точки зрения закона, подписан только тот документ, в&nbsp;которой можно достоверно установить, кто именно его подписал. Подтвердите, пожалуйста, свою личность.
        </Alert>

        <Tabs className="PageRegistration_switch">
            {Object.keys(typesInfo).map(key => (
                <TabItem
                    key={key}
                    active={legalForm === key}
                    onClick={() => setLegalForm(key)}>
                    {typesInfo[key]}
                </TabItem>
            ))}
        </Tabs>

        <TheFrame className="PageRegistration_formFrame">
            <RegistrationForm legalForm={legalForm}></RegistrationForm>
        </TheFrame>

    </div>
}