import React from 'react'
import DocumentsListItem from './DocumentsListItem'
import './DocumentsList.scss'

export default function ({ documents = [] }) {


    return <div className="DocumentsList">
        <ul className="DocumentsList_rows">
            {documents.map((d, idx) => (
                <li key={`key_${idx}`} className="DocumentsList_row">
                    <DocumentsListItem document={d} />
                </li>
            ))}
        </ul>
    </div>
}