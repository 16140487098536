import React from 'react'
import './Alert.scss'

export default function ({
        type = 'warning',
        title = '',
        text = '',
        className = '',
        children = null
    }) {

    const classList = `Alert Alert__${type} ${className}`

    const Content = () => (
        <div className="Alert_content">
            {title && <div className="Alert_title Text Text__md">{title}</div>}
            {text && <div className="Alert_text Text Text__md">{text}</div>}
        </div>
    )

    return <div className={classList}>
        <div className="Alert_icon">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 0C12.415 0 16 3.585 16 8C16 12.415 12.415 16 8 16C3.585 16 0 12.415 0 8C0 3.585 3.585 0 8 0ZM8.999 11C8.999 10.448 8.551 10 7.999 10C7.447 10 6.999 10.448 6.999 11C6.999 11.552 7.447 12 7.999 12C8.551 12 8.999 11.552 8.999 11ZM8.999 5C8.999 4.448 8.551 4 7.999 4C7.447 4 6.999 4.448 6.999 5V8C6.999 8.552 7.447 9 7.999 9C8.551 9 8.999 8.552 8.999 8V5Z" fill="#333333"/>
            </svg>
        </div>
        {children
            ? <div className="Alert_content">{children}</div>
            : <Content></Content>
        }
    </div>
}