import React from 'react'
import './DocumentsListItem.scss'
import { useHistory } from 'react-router-dom'
import Illustration from '../../img/illustrations/document-pencil.svg'
import Link from '../Link'

export default function ({ document })   {
    const history = useHistory()
    const navigate = (e, opts) => {
        e.preventDefault()
        history.push(opts)
    }

    return <div className="DocumentsListItem">
        <div className="DocumentsListItem_preview">
            <img src={Illustration}/>
        </div>
        <div className="DocumentsListItem_info">
            <div className="DocumentsListItem_title">
                <Link to={`/case/${document.public_id}`} size="l">
                    {document.name}
                </Link>
            </div>
            <div className="DocumentsListItem_meta">
                <div className="DocumentsListItem_company Text Text__md">
                    {[].concat(document.signatures || []).join(', ')}
                </div>
                {/*<div className="DocumentsListItem_person Text Text__md">*/}
                    {/*Васильев Николай Петрович*/}
                {/*</div>*/}
            </div>
        </div>
    </div>
}