import React from 'react'
import './layout.scss'
import PageHeader from '../Header'
import PageFooter from '../Footer'

export default function ({ className = '', children }) {
    return <div className={`Layout ${className}`}>
        <PageHeader className="Layout_header"></PageHeader>
        <main className="Layout_main">
            <div className="Main_inner container">
                {children}
            </div>
        </main>
        <PageFooter className="Layout_footer"></PageFooter>
    </div>
}