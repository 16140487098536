import React from 'react'
import SigninForm from '../../components/Forms/SigninForm'
import TheFrame from '../../components/Frame/index'
import './signin.scss'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { loadUserDocuments } from "../../store/actions/userDocuments"
import { loadProfile } from "../../store/actions/auth"

export default function () {
    const dispatch = useDispatch()
    const location = useLocation()
    const history = useHistory()
    const token = useSelector(state => state.auth.token)
    let prevURL = null

    if (location.state && location.state.from) {
        prevURL = `${location.state.from.pathname}${location.state.from.search ? location.state.from.search : ''}`
    }

    const nextURL = prevURL ? prevURL : '/profile'
    const submit = async () => {
        await dispatch(loadProfile())
        await dispatch(loadUserDocuments())
    }
    if (token) history.push(nextURL)

    return <div>
        <TheFrame className="PageSignin_inner">
            <SigninForm submit={submit}></SigninForm>
        </TheFrame>
    </div>
}