import React, {Fragment, useEffect} from 'react'
import NoMatch from '../Error/NoMatch'
import {loadDocument} from '../../store/actions'
import {connect} from 'react-redux'
import DocumentDetail from '../Document/Details'
import DocumentError from '../Error/Error'
import DocumentInit from './Init'
import Spinner from '../../components/Spinner/Spinner'

const statusToComponent = {
    INIT: DocumentInit,
    PROCESSING: DocumentInit,
    ERROR: DocumentError,
    SUCCESS: DocumentDetail,
}

const Case = ({match, dispatch, documents, auth}) => {
    const {id} = match.params
    const doc = documents && documents[id]

    useEffect(() => {
        dispatch(loadDocument(id))
    }, [id, auth.token])

    let StatusComponent = null

    if (doc && doc.status !== null) {

        if (doc.status === 'NOT_FOUND') {
            return <NoMatch />
        }

        if (doc.status === 'ERROR') {
            return <DocumentError text="При обработке файла произошла ошибка."/>
        }

        StatusComponent = statusToComponent[doc.status]

        return StatusComponent ? <StatusComponent id={id} document={doc.data} dispatch={dispatch} /> : <Spinner />
    }

    return <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Spinner />
    </div>
}

export default connect(
    state => ({
        documents: state.documents,
        auth: state.auth,
    }),
    null,
)(Case)
