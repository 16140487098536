import React from 'react'
import {Redirect} from 'react-router-dom'
import Route from './Route'
import { useSelector } from 'react-redux'

const PrivateRoute = ({component: Component, ...rest}) => {
    const auth = useSelector((state) => state.auth)

    return (
    <Route
      {...rest}
      render={props => {
        if (auth.token !== null) {
          return <Component {...props} />
        }

        if (auth.loading) {
          return null
        }

        return (
          <Redirect
            to={{
              pathname: '/login',
              state: {from: props.location},
            }}
          />
        )
      }}
    />
  )
}

export default PrivateRoute
