import React from 'react'
// import Link from 'arui-feather/link'
import './link.scss'
import { Link } from 'react-router-dom'

export default function (props) {
    const {
        className = '',
        to,
        url,
        line = true,
        color = false,
        ...other
    } = props

    const classList = `Link Link__${line ? 'line' : 'noline'} ${color ? 'Link__color' : ''} ${className}`

    if (to) {
        return <Link to={to} className={classList} {...other}></Link>
    } else {
        return <a href={url} className={classList} {...other}></a>
    }
}