// import {combineReducers} from 'redux'

import {reducer as formReducer} from 'redux-form'
import {reducer as authReducer} from './auth'
import {reducer as documentsReducer} from './documents'
import {reducer as userDocumentsReducer} from './userDocuments'

export const rootReducer = {
    auth: authReducer,
    documents: documentsReducer,
    userDocuments: userDocumentsReducer,
    form: formReducer,
}

// export const rootReducer = combineReducers({
//   auth: authReducer,
//   documents: documentsReducer,
//   userDocuments: userDocumentsReducer,
//   form: formReducer,
// })


