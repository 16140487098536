import React from 'react'
// import PhoneInput from 'arui-feather/phone-input'
import PhoneInput from 'react-phone-number-input/bundle/react-phone-number-input-mobile.js'
import 'react-phone-number-input/bundle/style.css'
import './PhoneInput.scss'

export default function ({
         className = '',
         size = 's',
         view = 'filled',
         ...rest
     }) {
    return <PhoneInput
        className="PhoneInputInput InputPhone"
        view={view}
        size={size}
        {...rest} />
}
