import { callAPI } from '../utils/api'
import axios from 'axios'

export default {
    async uploadDocument (file) {
        const fd = new FormData
        fd.append('file_uploaded', file, file.name)
        const { data } = await callAPI('upload/', fd, { 'Content-Type': 'multipart/form-data' })
        if (typeof data === 'string') return JSON.parse(data)
        return data
    }
}