import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Button from '../Button'
import Link from '../Link'
import './Document.scss'
import {formatPartner} from '../../utils/formatPartner'
import {formatCertName} from "../../utils/format"
import {formatDate} from "../../utils/date"
// import DocumentSignForm from '../Forms/DocumentSignForm'
import SkillboxSignForm from "../Forms/SkillboxSignForm";
import Modal from '../Modal/Modal'
import Input from '../../components/Input'
import LinkIcon from '../../icons/link.svg'
import Icon from '../Icon/Icon'
import Illustration from '../../img/illustrations/document-pencil.svg'
import * as STATUSES from '../../store/profile.statuses'


const doc = document

const Document = function ({ document, history, profile }) {
    const { meta, url, hashsum, is_signed_by_user, signatures } = document
    const { executor } = meta
    const [inited, setInited] = useState(false)
    const [copied, setCopied] = useState(false)
    const demo = true
    const hasClipboardFeature = !!navigator.clipboard

    useEffect(() => {
        doc.title = `Сертификат онлайн подписи | ${formatCertName(document.filename)}`
    }, [])

    useEffect(() => {
        if (profile && profile.status !== STATUSES.APPROVED) {
            history.push('/profile')
        }
    }, [profile])

    function onSigned () {
        setInited(false)
    }

    function print () {
        window.print()
    }

    async function copyLink () {
        await navigator.clipboard.writeText(window.location.href)
        setCopied(true)
        setTimeout(() => setCopied(false), 2000)
    }

    // async function getUsersData () {
    //
    // };

    return <div className="Document">
        <div className="Document_main">
            <ul className="Document_properties">
                {meta.customer
                    ? <li className="Document_property">
                        <div className="Document_propertyLabel Text Text__lg">Заказчик</div>
                        <div className="Document_propertyValue Text Text__lg">
                            {formatPartner(meta.customer) }
                        </div>
                    </li>
                    : null
                }
                {/*<li className={"Document_property border-line"}>*/}
                {/*    <div className="Document_propertyLabel Text Text__mdx">*/}
                {/*        Необходимо забрать заказ на имя Третьяковой Александры из супермаркета Азбука Вкуса... откуда берём текст?*/}
                {/*    </div>*/}
                {/*</li>*/}
                {/*<li className={"Document_property border-line"}>*/}
                {/*    <div className="Document_propertyLabel Text Text__mdx gray">*/}
                {/*        Заказчик*/}
                {/*    </div>*/}
                {/*    <div className="Document_propertyValue Text Text__mdx">*/}
                {/*        *Данные заказчика - название / имя**/}
                {/*    </div>*/}
                {/*    <div className="Document_propertyLabel Text Text__mdx gray">*/}
                {/*        Исполнитель*/}
                {/*    </div>*/}
                {/*    <div className="Document_propertyValue Text Text__mdx">*/}
                {/*        *Данные исполнителя - ФИО, телефон**/}
                {/*    </div>*/}
                {/*</li>*/}

                <li className="Document_property">
                    {/*<div className="Document_propertyLabel Text Text__mdx gray">*/}
                    {/*    Договор на выполнение задания*/}
                    {/*</div>*/}
                    <div className="Document_propertyLabel Text Text__mdx">
                        <Link href={url} color line={false} target="_blank" className="break-all">
                            Просмотреть документ
                        </Link>
                    </div>
                </li>

                <li className="Document_property">
                    <div className="Document_propertyLabel Text Text__mdx">
                        <b>Хэш (SHA-256)</b> <br />
                        {hashsum}
                    </div>
                </li>

                <li className="Document_property">
                    <div className="Document_propertyLabel Text Text__mdx gray">
                        Кто подписал
                    </div>
                </li>
                

                {signatures.length === 1 && (
                    <li className='Document_property' key={signatures[0].inn}>
                        <div className='Document_propertyValue Text Text__mdx'>
                            <div>
                                {signatures[0].inn ? (
                                    <div>
                                        {signatures[0].full_name} (ИНН: {signatures[0].inn}) в {formatDate(signatures[0].date)}{' '} при&nbsp;
                                        помощи секретного кода, отправленного на&nbsp;номер телефона {signatures[0].phone}{' '}
                                    </div>
                                ) : (
                                    <div>
                                        Неидентифицированный пользователь в {formatDate(signatures[0].date)} при&nbsp;помощи секретного кода,
                                        отправленного на&nbsp;номер телефона {signatures[0].phone}{' '}
                                    </div>
                                )}
                            </div>
                        </div>
                    </li>
                )}
                {signatures.length ===2 && (
                    <li className='Document_property' key={signatures[0].inn}>
                        <div className='Document_propertyValue Text Text__mdx'>
                            <div>
                                {signatures[0].inn ? (
                                    <div>
                                        {signatures[0].full_name} (ИНН: {signatures[0].inn}) в {formatDate(signatures[0].date)}{' '} при&nbsp;помощи
                                        секретного кода, отправленного на&nbsp;номер телефона {signatures[0].phone}{' '}
                                    </div>
                                ) : (
                                    <div>
                                        Неидентифицированный пользователь в {formatDate(signatures[0].date)} при&nbsp;помощи секретного кода,
                                        отправленного на&nbsp;номер телефона {signatures[0].phone}{' '}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='Document_propertyValue Text Text__mdx'>
                            <div>
                                {signatures[1].inn ? (
                                    <div>
                                        {signatures[1].full_name} (ИНН: {signatures[1].inn}) в {formatDate(signatures[1].date)} при&nbsp;помощи
                                        секретного кода, отправленного на&nbsp;номер телефона {signatures[1].phone}{' '} </div>
                                    ) : (
                                    <div>
                                        Неидентифицированный пользователь в {formatDate(signatures[1].date)} при&nbsp;помощи секретного кода,
                                        отправленного на&nbsp;номер телефона {signatures[1].phone}{' '}
                                    </div>
                                )}
                            </div>
                        </div>
                    </li>
                )}

                {/* {signatures.length === 1 && (
                    <li className='Document_property' key={signatures[0].inn}>
                    <div className='Document_propertyValue Text Text__mdx'>
                        <div>
                        {signatures[0].inn ? (
                            <div>
                            {signatures[0].full_name} (ИНН: {signatures[0].inn}) в {formatDate(signatures[0].date)}{' '}
                            &nbsp; Влодавская Наталья Владимировна +79161750306 </div>
                        ) : (
                            <div>
                            Неидентифицированный пользователь в {formatDate(signatures[0].date)} при&nbsp;помощи секретного кода,
                            отправленного на&nbsp;номер телефона {signatures[0].phone}{' '}
                            </div>
                        )}
                        </div>
                    </div>
                    </li>
                )}
                {signatures.length > 1 &&
                    signatures.map((signature) => (
                    <li className='Document_property' key={signature.inn}>
                        <div className='Document_propertyValue Text Text__mdx'>
                        <div>
                            {signature.inn ? (
                            <div>
                                {signature.full_name} (ИНН: {signature.inn}) в {formatDate(signature.date)} при&nbsp;помощи
                                секретного кода, отправленного на&nbsp;номер телефона {signature.phone}{' '}
                            </div>
                            ) : (
                            <div>
                                Неидентифицированный пользователь в {formatDate(signature.date)} при&nbsp;помощи секретного кода,
                                отправленного на&nbsp;номер телефона {signature.phone}{' '}
                            </div>
                            )}
                        </div>
                        </div>
                    </li>
                    ))} */}

            </ul>

            <div className="Document_controls">

                {!is_signed_by_user
                    ? !inited
                        ? <Button
                            type="button"
                            onClick={() => setInited(true)}
                            view="extra"
                            width={'available'}
                            className={'Document_mainButton'}
                            noSizeChange={true}
                        >
                            Подписать
                        </Button>
                        : <div>
                            <SkillboxSignForm onSigned={onSigned} demo={demo} id={document.public_id}/>
                        </div>
                    : <Button
                        type="button"
                        onClick={print}
                        view="action"
                        width={'available'}
                        className={'Document_mainButton'}
                        noSizeChange={true}
                    >
                        Распечатать
                    </Button>
                }

            </div>
        </div>

        {/*<Modal isOpen={inited} onRequestClose={() => setInited(false)}>*/}
        {/*    <DocumentSignForm onSigned={onSigned} demo={demo} id={document.public_id}/>*/}
        {/*</Modal>*/}

    </div>
}

export default connect(
    state => ({profile: state.auth.profile}),
    null
) (withRouter(Document))
