import React, { useState } from 'react'
import TheFrame from '../../components/Frame/index'
import NewDocumentForm from '../../components/Forms/NewDocumentForm'
import './PageAdd.scss'

export default function ({partner, history, dispatch}) {
    return <div className="PageAddDocument Page">
        <h1 className="Title PageAddDocument_title Page_title">
            Загрузка документа
        </h1>
        <TheFrame>
            <NewDocumentForm></NewDocumentForm>
        </TheFrame>
    </div>
}