import React, { useState } from 'react'
import Form from '../Form'
import FormField from '../FormField'
import Input from '../Input'
import Button from '../Button'
import Dropzone from '../../components/Dropzone/Dropzone'
import profileService from '../../services/profile'
import {useDispatch} from 'react-redux'
import {loadProfile} from "../../store/actions/auth"
import {useHistory} from 'react-router-dom'
import * as validate from '../../utils/validate'
import TYPES from '../../store/customer.types'
import {billRequisites} from "../../store/other"
import './RegistrationForm.scss'
import CheckBox from 'arui-feather/checkbox';

export default function ({ legalForm = TYPES.TYPE_PERSON }) {
    const dispatch = useDispatch()
    const history = useHistory()
    const [inn, setInn] = useState()
    const [email, setEmail] = useState()
    const [address, setAddress] = useState([])
    const [photo, setPhoto] = useState([])
    const [video, setVideo] = useState([])
    const [innError, setInnError] = useState(null)
    const [emailError, setEmailError] = useState(null)
    const [filesError, setFilesError] = useState(null)
    const [checkBox, setCheckBox] = useState(false)
    const isValid = address.length && photo.length && video.length

    async function submit () {
        const data = { inn, email, legal_form: legalForm }
        const einnError = !inn ? 'Некорректный ИНН' : null
        const eemailError = (validate.email(email) || !email) ? 'Некорректный e-mail' : null
        setInnError(einnError)
        setEmailError(eemailError)

        if (einnError || eemailError) return

        if (legalForm === TYPES.TYPE_PERSON) {
            const hasFiles = photo.length && address.length && video.length
            setFilesError(hasFiles ? null : 'Пожалуйста, прикрепите файлы')
            if (!hasFiles) return
        
            await profileService.commitPersonData({
                main: photo[0],
                registration: address[0],
                selfie: video[0],
                ...data
            })
        } else {
            await profileService.commitOfficialData(data)
        }

        await dispatch(loadProfile())
        history.push('/profile')
    }

    return <Form onSubmit={submit} className="RegistrationForm">
        <div className="RegistrationForm_fields">
            <FormField>
                <div className="Text Text__md">ИНН</div>
                <Input name="inn" error={innError} value={inn} onChange={setInn} width="available" />
            </FormField>
            <FormField>
                <div className="Text Text__md">E-mail</div>
                <Input name="email" error={emailError} value={email} onChange={setEmail} width="available" />
            </FormField>
        </div>

        {legalForm === TYPES.TYPE_PERSON
            ? <div className="RegistrationForm_caseDetails">
                <ol className="List List__ordered List__vertical">
                    <li>
                        <div>
                            <p className="Text Text__md">Страница паспорта с&nbsp;фотографией</p>
                            <div style={{ marginTop: 4 }}>
                                <Dropzone onChange={setPhoto}>Прикрепить фото</Dropzone>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div>
                            <p className="Text Text__md">Страница паспорта с&nbsp;регистрацией</p>
                            <div style={{ marginTop: 4 }}>
                                <Dropzone onChange={setAddress}>Прикрепить фото</Dropzone>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div>
                            <p className="Text Text__md">
                                Запишите на&nbsp;телефон селфи-видео с&nbsp;паспортом,
                                в&nbsp;котором вы&nbsp;произносите свой номер телефона.
                                Должно быть видно вас и&nbsp;фото на&nbsp;паспорте
                            </p>
                            <div style={{ marginTop: 4 }}>
                                <Dropzone onChange={setVideo}>Прикрепить видео</Dropzone>
                            </div>
                        </div>
                    </li>
                </ol>
                <div style={{ marginTop: 12 }} className="Text Text__md Text__error">{filesError}</div>
            </div>
            : <div>
                <p className="Text Text__md">
                    Для идентификации необходимо внести 1₽&nbsp;с&nbsp;вашего расчетного счета в&nbsp;банке по&nbsp;этим
                    реквизитам&nbsp;(<a target="_blank" className="Link Link__color" href={billRequisites.pdf}>pdf</a>)
                </p>
            </div>
        }
        <div className={"RegistrationForm_checkbox"}>
            {
                <CheckBox
                    text={ <div className={'RegistrationForm_checkbox_inner'}>Я согласен с условиями использования и обработки <a href="http://legium.io/terms">персональных данных</a></div>}
                    size={ 'l' }
                    checked={checkBox}
                    onChange={(isChecked) => isChecked ? setCheckBox(true) : setCheckBox(false) }
                />
            }
        </div>
        <div className={"RegistrationForm_checkbox"}>
            {
                <CheckBox
                    text={ <div className={'RegistrationForm_checkbox_inner'}>Принимаю условия <a href="https://skillbox.legium.io/terms">соглашения об использовании электронного документооборота</a> c ООО "Скилбокс"</div>}
                    size={ 'l' }
                    checked={checkBox}
                    onChange={(isChecked) => isChecked ? setCheckBox(true) : setCheckBox(false) }
                />
            }
        </div>
        <div className="RegistrationForm_submit">
            <Button disabled={!checkBox} type="submit" view="extra">Продолжить</Button>
        </div>
    </Form>
}