import React, { useEffect, useState } from 'react'
import Button from '../Button'
import { useHistory } from 'react-router-dom'
import './DocumentsEmpty.scss'
import Illustration from '../../img/illustrations/document-pencil.svg'

export default function () {
    const history = useHistory()

    const newDocument = () => history.push('/case/new')

    return <div className="DocumentsEmpty">
        <figure className="DocumentsEmpty_figure">
            <img src={Illustration} alt="Иллюстрация" className="DocumentsEmpty_image"/>
            <figcaption className="Text Text__md DocumentsEmpty_text">
                Нет документов на&nbsp;подпись
            </figcaption>
        </figure>
        <Button className="DocumentsEmptyUploadButton"
                onClick={newDocument}
                view="extra">
            Загрузить документ
        </Button>
    </div>
}